import React from "react";
import Sejarah from "./sejarah";
import VisiMisi from "./visi_misi";

const About = () => {
  return (
    <section
      id="2"
      className="w-11/12 md:w-10/12 lg:w-9/12 flex flex-col space-y-10">
      <h2 className="font-semibold text-2xl md:text-4xl">Tentang Kami</h2>
      <p className="text-justify text-sm md:text-xl">
        PT Sinergi Transaksi Teknologi Indonesia (STTI) didirikan dengan visi
        untuk menyediakan layanan transfer dana yang inovatif dan terdepan di
        Indonesia. Sejak didirikan, kami telah memberikan solusi transfer dana
        yang handal dan efisien bagi pelanggan kami.
      </p>
      <Sejarah />
      <VisiMisi />
    </section>
  );
};

export default About;
