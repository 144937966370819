import { Menu } from "antd";
import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";

const TopBar = () => {
  const items = [
    {
      label: "Beranda",
      key: "1",
    },
    {
      label: "Tentang Kami",
      key: "2",
    },
    {
      label: "Keunggulan",
      key: "3",
    },
    {
      label: "Layanan",
      key: "4",
    },
  ];

  const [current, setCurrent] = useState("1");
  const [openMenu, setOpenMenu] = useState(false);

  const openDropdown = () => {
    openMenu ? setOpenMenu(false) : setOpenMenu(true);
  };

  const handleScroll = (e) => {
    setCurrent(e.key);
    const element = document.getElementById(e.key);
    const headerOffset = 90;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  return (
    <section className="fixed w-full flex justify-center items-center bg-[#27242b] py-3 z-[100]">
      <div className="w-11/12 md:w-10/12 lg:w-9/12 flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <img
            className="w-12 object-contain"
            src="./assets/logo-white.png"
            alt="logo"
          />
          <p className="hidden md:block font-semibold  text-white">
            PT SINERGI TRANSAKSI TEKNOLOGI INDONESIA
          </p>
          <p className="block md:hidden text-sm font-semibold  text-white">
            PT SINERGI TRANSAKSI
            <br />
            TEKNOLOGI INDONESIA
          </p>
        </div>
        <Menu
          className="hidden flex-1 lg:flex justify-end font-semibold text-base h-[3.5vh]"
          onClick={handleScroll}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
        />
        <div className="relative lg:hidden cursor-pointer">
          <div onClick={openDropdown}>
            {openMenu ? (
              <IoCloseSharp
                className="text-white text-2xl"
                onClick={() =>
                  openMenu ? setOpenMenu(false) : setOpenMenu(true)
                }
              />
            ) : (
              <GiHamburgerMenu
                className="text-white text-2xl"
                onClick={() =>
                  openMenu ? setOpenMenu(false) : setOpenMenu(true)
                }
              />
            )}
            <div
              className={`absolute right-0 top-14 bg-[#27242b]  ${
                openMenu ? "block" : "hidden"
              }`}>
              <Menu
                className="font-semibold"
                onClick={handleScroll}
                selectedKeys={[current]}
                mode="vertical"
                items={items}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopBar;
