import React from "react";

const CardKeunggulan = ({ icon, children, title }) => {
  return (
    <section className="shadow-xl rounded-lg border-2 border-b-8 border-b-[#27242b] border-[#a9a7aa] relative p-6 sm:p-8 flex flex-col h-[26vh]  md:h-[34vh] w-full md:w-[20rem]">
      <div className="flex flex-col space-y-4">
        {icon}
        <div className="space-y-3">
          <h3 className="font-semibold text-xl md:text-2xl">{title}</h3>
          <p className="text-sm md:text-base text-justify h-[4vh]">{children}</p>
        </div>
      </div>
    </section>
  );
};

export default CardKeunggulan;
