import React from "react";
import CardKeunggulan from "./card-keunggulan";
import { BsShieldLock } from "react-icons/bs";
import { TbDeviceAnalytics } from "react-icons/tb";
import { TiLightbulb } from "react-icons/ti";
import { RiCustomerService2Fill } from "react-icons/ri";
import { MdOutlineManageSearch } from "react-icons/md";

const Keunggulan = () => {
  return (
    <section
      id="3"
      className="w-11/12 md:w-10/12 lg:w-9/12 flex flex-col space-y-14">
      <h2 className="font-semibold text-xl md:text-4xl">Keunggulan</h2>
      <div className="flex flex-wrap justify-center gap-y-8 md:gap-y-16 gap-4 md:gap-x-10">
        <CardKeunggulan
          icon={
            <BsShieldLock className="text-4xl md:text-6xl text-white bg-[#27242b] p-1 rounded-full " />
          }
          title="Keamanan Terjamin">
          Sistem keamanan terkini untuk melindungi setiap transaksi Anda
        </CardKeunggulan>
        <CardKeunggulan
          icon={
            <TbDeviceAnalytics className="text-4xl md:text-6xl text-white bg-[#27242b] p-1 rounded-full" />
          }
          title="Efisiensi">
          Setiap transaksi dilakukan dengan cepat dan efisien.{" "}
        </CardKeunggulan>
        <CardKeunggulan
          icon={
            <RiCustomerService2Fill className="text-4xl md:text-6xl text-white bg-[#27242b] p-1 rounded-full" />
          }
          title="Layanan Pelanggan">
          Tim layanan pelanggan kami siap membantu Anda dengan pelayanan yang
          ramah dan responsif.{" "}
        </CardKeunggulan>
        <CardKeunggulan
          icon={
            <TiLightbulb className="text-4xl md:text-6xl text-white bg-[#27242b] p-1 rounded-full" />
          }
          title="Inovasi">
          Kami bertujuan untuk memberikan layanan transfer dana yang efisien,
          menghemat waktu dan biaya bagi pelanggan.{" "}
        </CardKeunggulan>
        <CardKeunggulan
          icon={
            <MdOutlineManageSearch className="text-4xl md:text-6xl text-white bg-[#27242b] p-1 rounded-full" />
          }
          title="Keterbukaan">
          Kami mengutamakan komunikasi terbuka dan transparan dengan pelanggan
          dan mitra bisnis kami.{" "}
        </CardKeunggulan>
      </div>
    </section>
  );
};

export default Keunggulan;
