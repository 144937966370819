


const Sertifikat = () => {
  return (
    <section id="2" className="w-11/12 md:w-10/12 lg:w-9/12 space-y-10">
      <h2 className="font-semibold text-2xl md:text-4xl">Sertifikat</h2>

      <div className="flex flex-wrap justify-center items-center gap-8">
      <img src='./assets/sertif1.png' className="w-[40rem] object-contain" alt='sertif' />

      <img src='./assets/sertif2.png' className="w-[25rem] object-contain" alt='sertif' />

      </div>
    </section>
  );
};

export default Sertifikat;
