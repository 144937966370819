import React from "react";

const Sejarah = () => {
  return (
    <section className="flex flex-col space-y-8 xl:space-y-0">
      <h2 className="md:text-xl text-white w-64 px-3 py-2 bg-[#27242b]">
        Sejarah singkat
      </h2>
      <div className="grid md:grid-cols-7 gap-y-5 md:gap-y-0 md:gap-x-8 lg:gap-x-14 items-center">
        <p className="md:col-span-4 text-justify text-sm md:text-xl">
          PT Sinergi Transaksi Teknologi Indonesia lahir untuk menjawab
          kebutuhan konsumen dan bisnis akan solusi transfer uang yang efisien
          dan aman. Perusahaan terus berupaya untuk berinovasi dan beradaptasi
          dengan cepat terhadap perkembangan teknologi yang pesat. Dengan
          memanfaatkan teknologi terkini dan menjalin kemitraan strategis,
          perusahaan dapat menawarkan beragam layanan yang disesuaikan untuk
          memenuhi tuntutan pasar yang dinamis.
        </p>
        <img
          className="md:col-span-3 rounded-lg object-cover w-full h-[20vh] md:h-full"
          src="./assets/sejarah.jpg"
          alt="serjarah"
        />
      </div>
    </section>
  );
};

export default Sejarah;
