const VisiMisi = () => {
  return (
    <section className="grid grid-cols-5  md:gap-x-8 lg:gap-x-14 ">
      <img className="hidden md:block md:col-span-2 rounded-lg object-cover h-full" src="./assets/visi_misi.jpg" alt="visiMisi" />
      <div className="col-span-5  md:col-span-3">
        <div className="space-y-8">
          <div className="space-y-8">
            <h2 className="md:text-xl text-white w-64 px-3 py-2 bg-[#27242b]">Visi</h2>
            <p className="text-justify text-sm md:text-xl">Menjadi mitra terpercaya dalam layanan transfer dana yang inovatif dan efisien bagi masyarakat Indonesia.</p>
          </div>
          <div className="space-y-8">
            <h2 className="md:text-xl text-white w-64 px-3 py-2 bg-[#27242b]">Misi</h2>
            <div className="space-y-2 md:space-y-4">
              <p className="flex space-x-1 text-justify text-sm md:text-xl">
                <span>1.</span>
                <span>Memberikan layanan transfer dana yang cepat, aman, dan terjangkau</span>
              </p>
              <p className="flex space-x-1 text-justify text-sm md:text-xl">
                <span>2.</span>
                <span>Mengintegrasikan teknologi terkini untuk meningkatkan efisiensi dan keamanan dalam proses transfer dana.</span>
              </p>
              <p className="flex space-x-1 text-justify text-sm md:text-xl">
                <span>3.</span>
                <span>Memberikan pelayanan pelanggan yang prima dan responsif.</span>
              </p>
              <p className="flex space-x-1 text-justify text-sm md:text-xl">
                <span>4.</span>
                <span>Menjadi agen perubahan dalam industri transfer dana di Indonesia dengan terus mengembangkan solusi inovatif.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VisiMisi;
