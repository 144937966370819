export const theme = {
  components: {
    Menu: {
      itemSelectedColor: "white",
      horizontalItemSelectedColor: "white",
      itemBg: "transparent",
      itemSelectedBg: "transparent",
      itemColor: "#94a1b2",
      itemHoverColor: "#94a1b2",
      horizontalItemSelectedBg: "transparent",
      darkSubMenuItemBg: "transparent",
    },
  },
};
