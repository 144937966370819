import React from "react";
import TopBar from "../../components/topbar";
import Banner from "../../components/home/banner";
import About from "../../components/home/about";
import Keunggulan from "../../components/home/keunggulan";
import Service from "../../components/home/service";
import Sertifikat from "../../components/home/sertif";
// import Contact from "../../components/home/contact";

const HomePage = () => {
  return (
    <section className="flex flex-col items-center relative overflow-x-hidden">
      <TopBar />
      <Banner />
      <div className="flex flex-col space-y-12 md:space-y-24 items-center my-7 md:my-[10vh]">
        <About />
        <Keunggulan />
        <Service />
        <Sertifikat/>
        {/* <Contact /> */}
      </div>
      <div className="w-full bg-[#27242b] py-5 flex justify-center mt-[10vh]">
        <i className="text-white text-xs md:text-base ">
          © Copyright 2024 PT Sinergi Transaksi Teknologi Indonesia (STTI)
        </i>
      </div>
    </section>
  );
};

export default HomePage;
