import React from "react";

const Service = () => {
  return (
    <section
      id="4"
      className="w-11/12 md:w-10/12 lg:w-9/12 flex flex-col space-y-14">
      <h2 className="font-semibold text-2xl md:text-4xl ">Layanan</h2>
      <div className="grid grid-cols-5 md:gap-x-8  ">
        <div className="col-span-5  lg:col-span-3">
          <div className="space-y-8">
            <div className="space-y-2 md:space-y-4">
              <div className="grid grid-cols-12 text-justify text-sm md:text-xl">
                <p className="col-span-2 bg-[#27242b] w-[30px] md:w-[40px] h-[30px] md:h-[40px] px-3 flex justify-center items-center rounded-full text-white font-semibold">
                  1
                </p>
                <div className="col-span-8 space-y-3">
                  <p className="font-semibold text-base md:text-2xl">
                    Transfer Dana
                  </p>
                  <p className="text-justify">
                    Layanan transfer dana dalam negeri dengan berbagai opsi,
                    termasuk transfer instan dan transfer reguler.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 text-justify text-sm md:text-xl">
                <p className="col-span-2 bg-[#27242b] w-[30px] md:w-[40px] h-[30px] md:h-[40px] px-3 flex justify-center items-center rounded-full text-white font-semibold">
                  2
                </p>
                <div className="col-span-8 space-y-3">
                  <p className="font-semibold text-base md:text-2xl">
                    Transfer Dana Domestik
                  </p>
                  <p className="text-justify">
                    {" "}
                    Layanan transfer dana antar bank di Indonesia dengan cepat
                    dan aman.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 text-justify text-sm md:text-xl">
                <p className="col-span-2 bg-[#27242b] w-[30px] md:w-[40px] h-[30px] md:h-[40px] px-3 flex justify-center items-center rounded-full text-white font-semibold">
                  3
                </p>
                <div className="col-span-8 space-y-3">
                  <p className="font-semibold text-base md:text-2xl">
                    Layanan Pembayaran
                  </p>
                  <p className="text-justify">
                    Pembayaran tagihan, pembelian pulsa, dan pembelian tiket
                    dengan cepat dan mudah.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 text-justify text-sm md:text-xl">
                <p className="col-span-2 bg-[#27242b] w-[30px] md:w-[40px] h-[30px] md:h-[40px] px-3 flex justify-center items-center rounded-full text-white font-semibold">
                  4
                </p>
                <div className="col-span-8 space-y-3">
                  <p className="font-semibold text-base md:text-2xl">
                    {" "}
                    Solusi Teknologi
                  </p>
                  <p className="text-justify">
                    Solusi teknologi terkini dalam pengelolaan transaksi
                    keuangan untuk perusahaan Anda. Keunggulan
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="hidden lg:block lg:col-span-2 rounded-lg object-cover h-full"
          src="./assets/service.jpg"
          alt="visiMisi"
        />
      </div>
    </section>
  );
};

export default Service;

// 1. Transfer Dana Domestik: Layanan transfer dana antar bank di Indonesia dengan cepat dan aman.
// 2. Transfer Dana Internasional: Kemudahan dalam melakukan transfer dana ke luar negeri dengan berbagai mata uang yang berlaku.
// 3. Layanan Pembayaran: Pembayaran tagihan, pembelian pulsa, dan pembelian tiket dengan cepat dan mudah.
// 4. Solusi Teknologi: Solusi teknologi terkini dalam pengelolaan transaksi keuangan untuk perusahaan Anda.
// Keunggulan
