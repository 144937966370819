import React from "react";

const Banner = () => {
  return (
    <section id="1" className="flex justify-center items-center">
      <div className="w-11/12 md:w-10/12 lg:w-9/12 flex flex-col space-y-8 md:space-y-12 items-center text-white absolute z-50">
        <h1 className="font-semibold text-center text-2xl md:text-5xl drop-shadow-md">
          Selamat datang di PT Sinergi Transaksi Teknologi Indonesia (STTI)
        </h1>
        <p className="text-justify md:text-center font-medium md:text-2xl drop-shadow-md">
          Kami adalah penyedia layanan transfer dana yang handal dan terpercaya.
          Dengan komitmen kami untuk memberikan solusi transfer dana yang aman,
          cepat, dan efisien, kami siap menjadi mitra terbaik Anda dalam
          kebutuhan transaksi keuangan.
        </p>
      </div>
      <div className="h-[60vh] md:h-[80vh] w-screen bg-gradient-banner absolute" />
      <img
        className="h-[60vh] md:h-[80vh] w-screen object-cover"
        src="./assets/banner.jpg"
        alt="banner"
      />
    </section>
  );
};

export default Banner;
